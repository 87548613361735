<template>
  <v-card
    min-width="320"
    max-width="340"
    width="100%"
    elevation="8"
    light
    :class="`rounded-xl py-1 px-1 ${
      isSelectable && thisAddressIsSelected && 'blue-grey lighten-4'
    }`"
  >
    <v-card-title primary-title>
      {{ $t("street") }}: {{ address.street }}
    </v-card-title>
    <v-card-text class="pb-1">
      <p class="mb-1">{{ $t("postalCode") }}: {{ address.postal_code }}</p>
      <p class="mb-1">{{ $t("city") }}: {{ address.city }}</p>
      <p class="mb-1">{{ $t("state") }}: {{ address.state }}</p>
      <p class="mb-1" v-if="address.reference">
        {{ $t("comment") }}: {{ address.reference }}
      </p>
    </v-card-text>
    <v-card-actions class="mb-1 justify-space-between">
      <v-btn
        class="font-weight-black"
        :block="!isSelectable"
        :text="isSelectable"
        color="primary"
        @click="handleDeleteAddress"
      >
        {{ $t("delete") }}
      </v-btn>
      <v-btn
        text
        class="font-weight-black"
        v-if="isSelectable"
        :block="!isSelectable"
        color="success"
        @click="handleToggleSelectAddress"
      >
        {{ thisAddressIsSelected ? $t("deselect") : $t("select") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { addressesCtrl } from "@/controllers";
import { getErrorPayloadForSnackbar } from "@/helpers";
import { useSnackbar } from "@/utils";
import {
  orderTypeStoreGetters,
  orderTypeStoreActions,
} from "@/store/modules/orderType/constNames";

export default {
  name: "UserAddress",
  props: {
    address: {
      type: Object,
      required: true,
    },
    isSelectable: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    addressesCtrl
      .getAddresses()
      .then((addresses) => {
        this.addresses = [...addresses];
      })
      .catch((error) => {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      })
      .finally(() => {
        this.loadingContent = false;
      });
  },
  methods: {
    handleToggleSelectAddress() {
      const isSelected =
        this.address.id ===
        this.$store.getters[orderTypeStoreGetters.GET_ADDRESS]?.id;
      this.$store.dispatch(
        orderTypeStoreActions.ADD_ADDRESS_TO_ORDER,
        isSelected ? null : { id: this.address.id, street: this.address.street }
      );
    },
    async handleDeleteAddress() {
      try {
        const { data, message } = await addressesCtrl.handleDeleteAddress(
          this.address.id
        );
        if (data && message) {
          this.$emit("delete-an-address", this.address.id);
          const snackbar = useSnackbar();
          snackbar.dispatchOpenSnackbar(message);
        }
      } catch (error) {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      }
    },
  },
  computed: {
    thisAddressIsSelected() {
      const storeAddress =
        this.$store.getters[orderTypeStoreGetters.GET_ADDRESS];
      return storeAddress?.id === this.address.id;
    },
  },
};
</script>
